<template>
  <div class="login-form">
    <div class="pt-lg-0 pt-5">
      <h2 class="bold-text text-dark">Welcome to TestApp.io</h2>
      <span class="text-muted bold-text font-size-h4">
        Already have an account?
        <router-link
          class="text-primary bold-text mx-2"
          :to="{ name: 'signin' }"
          >Signin</router-link
        >
      </span>
    </div>
    <!--  -->
    <v-card :disabled="busy" tile flat class="transparent mt-15">
      <signup-form @error="setErrorMessage" default-signup></signup-form>
    </v-card>
  </div>
</template>

<script>
import veeValidate from "@/mixins/veeValidate";
import SignupForm from "@/view/components/User/SignupForm";
import { mapMutations } from "vuex";

export default {
  components: { SignupForm },
  mixins: [veeValidate],
  name: "signup",
  metaInfo: {
    title: "TestApp.io - Portal Sign Up",
    meta: [
      // {
      //   name: "robots",
      //   content: "noindex,nofollow"
      // }
    ],
  },
  data() {
    return {
      errorMessage: "",
      busy: false,
    };
  },
  created() {
    if (this.$route.query && this.$route.query.plan) {
      this.setRedirectedFromPlans(this.$route.query.plan);
    }
  },
  methods: {
    ...mapMutations({
      setRedirectedFromPlans: "setRedirectedFromPlans",
    }),
    setErrorMessage(message) {
      this.errorMessage = message;
    },
  },
};
</script>
