<template>
  <v-form>
    <vue-inline-alert
      v-if="getAuthMessage.message"
      :message-type="getAuthMessage.type"
      :message="getAuthMessage.message"
    />
    <v-text-field
      label="Name"
      placeholder="Your name"
      filled
      autofocus
      id="signupName"
      v-model="form.name"
      v-validate="'required|max:20|min:3'"
      counter="20"
      v-bind="veeValidate('Name', 'Name')"
    />
    <v-text-field
      label="Email"
      placeholder="Your email address"
      filled
      id="signupEmail"
      v-model="form.email"
      v-validate="'required|email'"
      v-bind="veeValidate('Email', 'Email')"
      type="email"
    />
    <v-text-field
      label="Password"
      placeholder="Your password"
      filled
      id="signupPassword"
      v-model="form.password"
      v-validate="'required|max:30|min:3'"
      counter="30"
      v-bind="veeValidate('Password', 'Password')"
      type="password"
    />
    <v-select
      v-if="fromAppsumo"
      v-model="selectedPlan"
      placeholder="1 code for Starter and 2 codes for Pro"
      filled
      id="appSummoSelect"
      :items="['Starter', 'Pro']"
      v-validate="'required'"
      v-bind="veeValidate('Plan', 'Select Plan')"
    ></v-select>
    <v-text-field
      v-if="fromAppsumo && selectedPlan"
      label="AppSumo Code"
      id="appSummoCode1"
      placeholder="AppSumo code"
      filled
      v-model="form.promo.code"
      v-validate="'required'"
      v-bind="veeValidate('AppSumo Code', 'AppSumo Code')"
    />
    <v-text-field
      v-if="fromAppsumo && selectedPlan && selectedPlan === 'Pro'"
      placeholder="AppSumo code2"
      filled
      id="appSummoCode2"
      v-model="form.promo.code2"
      v-validate="'required'"
      v-bind="veeValidate('AppSumo Code', 'AppSumo Code2')"
    />
    <div class="pa-6 text-left">
      By creating an account, you agree to our
      <a
        @click.stop
        href="https://testapp.io/terms-and-conditions"
        target="_blank"
      >
        Terms & Conditions
      </a>
      and
      <a @click.stop href="https://testapp.io/privacy-policy" target="_blank">
        Privacy Policy
      </a>
    </div>
    <v-btn
      class="main primary mx-auto"
      :loading="busy"
      id="createAccount"
      @click.stop.prevent="onSubmit"
      :disabled="busy || errors.any()"
    >
      Create Account
    </v-btn>
    <social-auth
      :default-signin="defaultSignup"
      button-text="Sign up with"
      @loading="busy = $event"
      @success="$emit('success')"
    ></social-auth>
  </v-form>
</template>

<script>
import { SIGNUP, PURGE_AUTH_DATA } from "@/store/users/auth.module";
import veeValidate from "@/mixins/veeValidate";
import { mapGetters, mapMutations } from "vuex";
import { isEmpty } from "@/core/services/helper.service";
import SocialAuth from "@/view/components/User/SocialAuth";

export default {
  components: { SocialAuth },
  mixins: [veeValidate],
  props: {
    defaultSignup: {
      type: Boolean,
      default: false,
    },
    fromAppsumo: {
      type: Boolean,
      default: false,
    },
    email: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      form: {
        name: "",
        email: "",
        password: "",
        promo: {
          source: "appsumo",
          code: "",
          code2: "",
        },
      },
      errorMessage: "",
      message: "",
      busy: false,
      selectedPlan: "",
    };
  },
  computed: {
    ...mapGetters({
      getAuthMessage: "getAuthMessage",
    }),
  },
  created() {
    this.form.email = this.email || this.$route.params.email || "";
    if (isEmpty(this.$route.query)) {
      this.setAuthMessage({ message: "", type: "" });
    }
    if (this.$route.params.code) {
      this.form.code = this.$route.params.code;
    }
  },
  methods: {
    resetForm() {
      this.form = {
        name: "",
        email: "",
        password: "",
      };
      this.message = "";
      this.$nextTick(() => {
        this.$validator.errors.clear();
      });
    },
    ...mapMutations({
      setAuthMessage: "setAuthMessage",
    }),
    async onSubmit() {
      if (await this.validateAllFields()) {
        if (this.busy) return;
        this.busy = true;
        // clear existing errors
        this.errorMessage = null;
        await this.$store.dispatch(PURGE_AUTH_DATA);
        this.$store
          .dispatch(SIGNUP, {
            ...this.form,
            invite_id: this.$route.params.invite_id || "",
          })
          .then((response) => {
            this.handleSignupSuccess(response);
            this.$validator.errors.clear();
            this.setAuthMessage({
              message: response.message,
              type: "success",
            });
            this.$nextTick(() => {
              this.busy = false;
            });
          })
          .catch((err) => {
            this.$validator.errors.clear();
            this.handleSignupError(err);
            this.setAuthMessage({
              message: err.message,
              type: "error",
            });
            this.errorMessage = err.message;
            this.busy = false;
          });
      }
    },
    handleSignupSuccess(response) {
      if (this.fromAppsumo) {
        this.$router
          .push({
            name: "verify",
            query: { email: this.form.email, signup: true },
          })
          .catch(() => {});
      } else {
        if (this.defaultSignup) {
          this.$router
            .push({
              name: "verify",
              query: { email: this.form.email, signup: true },
            })
            .catch(() => {});
        } else {
          if (response.status === 4) {
            this.$emit("activated", {
              email: this.form.email,
              password: this.form.password,
            });
          } else {
            this.$emit("success", this.form.email);
          }
        }
      }
    },
    handleSignupError(err) {
      if (err.status === 1) {
        if (this.defaultSignup) {
          this.$router
            .push({
              name: "signin",
              query: { tab: 2 },
              params: { email: this.form.email },
            })
            .catch(() => {});
        }
        this.$emit("sign-in");
      } else if (err.status === 2) {
        if (this.defaultSignup) {
          this.$router
            .push({
              name: "verify",
              query: { email: this.form.email, signup: true },
            })
            .catch(() => {});
        }
        this.$emit("not-activated", this.form.email);
      } else if (err.status === 4) {
        this.$emit("activated", {
          email: this.form.email,
          password: this.form.password,
        });
      }
    },
  },
};
</script>

<style scoped></style>
