<template>
  <v-row class="my-3">
    <v-col cols="12" class="text-center text-muted">
      <div>- - - - - - - Or - - - - - -</div>
    </v-col>
    <v-col cols="12" class="text-center">
      <v-btn
        class="text-transform-none"
        :class="$vuetify.breakpoint.smAndDown ? 'mb-3' : 'mr-3 px-2'"
        large
        color="grey"
        outlined
        :block="$vuetify.breakpoint.smAndDown"
        :disabled="busy"
        @click="socialAuth('google')"
      >
        <v-row align="center">
          <v-col class="pr-0" cols="3">
            <v-img
              width="48"
              height="48"
              src="/media/svg/icons/google-icon.svg"
            ></v-img>
          </v-col>
          <v-col class="pl-0" cols="9">
            <span class="black--text">
              {{ buttonText }}
              Google
            </span>
          </v-col>
        </v-row>
      </v-btn>
      <v-btn
        @click="socialAuth('apple')"
        color="black"
        large
        :block="$vuetify.breakpoint.smAndDown"
        depressed
        :disabled="busy"
        class="text-transform-none pr-6 white--text"
      >
        <v-row>
          <v-col class="pr-0" cols="3">
            <v-icon left color="white" size="23" v-text="'mdi-apple'"></v-icon>
          </v-col>
          <v-col class="pl-0" cols="9">
            <span>
              {{ buttonText }}
              Apple
            </span>
          </v-col>
        </v-row>
      </v-btn>
    </v-col>
    <Modal v-model="successAuthModal" hideClose>
      <template #message>
        <v-row class="py-4">
          <v-col class="text-center" cols="12">
            <div class="text-h6" v-if="!firebaseUserName">
              We are authenticating your account, please wait...
            </div>
            <div class="text-h5" v-else>Welcome {{ firebaseUserName }}!</div>
          </v-col>
          <v-col v-if="firebaseUserName" class="text-center text-h6" cols="12">
            We are signing you in, please wait...
          </v-col>

          <v-col class="text-center" cols="12">
            <v-progress-circular
              indeterminate
              color="success"
            ></v-progress-circular>
          </v-col>
          <v-col class="text-center bodyFont" cols="12">
            It should be done within few moments
          </v-col>
        </v-row>
      </template>
    </Modal>
  </v-row>
</template>

<script>
import firebase from "firebase";
import { SIGNIN_WITH_TOKEN } from "@/store/users/auth.module";
import { mapMutations } from "vuex";
import StorageService from "@/core/services/localstorage.service";

export default {
  props: {
    buttonText: {
      type: String,
      default: "",
    },
    defaultSignin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      busy: false,
      successAuthModal: false,
      firebaseUserName: "",
    };
  },
  created() {
    if (StorageService.getItem("social_auth") === "true") {
      StorageService.removeItem("social_auth");
      this.successAuthModal = true;
      firebase
        .auth()
        .getRedirectResult()
        .then((result) => {
          if (result.user) {
            this.firebaseUserName = result.user.displayName;
            firebase
              .auth()
              .currentUser.getIdToken(false)
              .then((idToken) => {
                // Send idToken" to our API to get the Signin info
                this.signWithToken(idToken);
              })
              .catch((error) => {
                this.notifyUserMessage(error);
                this.busy = false;
                this.$emit("loading", false);
              });
          } else {
            //User cancelled the operation
            this.successAuthModal = false;
          }
        })
        .catch((err) => {
          this.notifyErrorMessage(
            err.message || "Something went wrong, please try again"
          );
          this.busy = false;
          this.$emit("loading", false);
        });
    }
  },
  methods: {
    ...mapMutations({
      setAuthMessage: "setAuthMessage",
    }),
    socialAuth(type) {
      this.setAuthMessage({ message: "", type: "" });
      if (this.busy) return;
      this.busy = true;
      this.$emit("loading", true);
      // For apple
      const appleProvider = new firebase.auth.OAuthProvider("apple.com");

      //For Google
      const googleProvider = new firebase.auth.GoogleAuthProvider();
      const provider = type === "google" ? googleProvider : appleProvider;

      firebase.auth().signInWithRedirect(provider);
      StorageService.setItem("social_auth", "true");
    },
    signWithToken(token) {
      this.$store
        .dispatch(SIGNIN_WITH_TOKEN, {
          token: token,
          invite_id: this.$route.params.invite_id || "",
        })
        .then(async (response) => {
          await firebase.auth().signOut();
          if (this.defaultSignin) {
            this.$nextTick(() => {
              if (response && !response.team) {
                this.$router.push({ name: "welcome" }).catch(() => {});
              } else {
                this.$router.push({ name: "dashboard" }).catch(() => {});
              }
            });
          } else {
            this.$emit("success", response.message);
          }
          this.busy = false;
          this.$emit("loading", false);
        })
        .catch((err) => {
          this.setAuthMessage({ message: err.message, type: "error" });
          this.busy = false;
          this.$emit("loading", false);
        });
    },
  },
};
</script>

<style scoped></style>
